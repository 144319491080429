import request from "@/utils/request";

export function getSystemLogs(page, size, order, by, search) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  const hasSearch = search ? "&searchTerm=" + search.searchTerm : "";
  return request({
    url: "api/monitor/events/system?page=" + page + "&size=" + size + orderBy + hasSearch,
    method: "get",
  });
}
