<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        search-label="Search logs"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:item.modul="{ item }">
          {{ item.modul }}
        </template>
        <template v-slot:item.severity="{ item }">
          <div class="d-flex align-center">
            <v-icon
              :class="severityClass(item.severity)"
              small
              class="mr-1 severity-icon"
            >
              mdi-view-grid
            </v-icon>
            <span class="text-white">
              {{ severityType(item.severity) }}
            </span>
          </div>
        </template>
        <template v-slot:item.message="{ item }">
          {{ item.message }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'created_at'"
                      width="150"
                      class="pl-3"
                    >
                      Created:
                    </td>
                    <td v-if="expandItem.value === 'created_at'">
                      {{ item.created_at }}
                    </td>

                    <td
                      v-if="expandItem.value === 'modul'"
                      width="150"
                      class="pl-3"
                    >
                      Object:
                    </td>
                    <td v-if="expandItem.value === 'modul'">
                      {{ item.modul ? item.modul : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'severity'"
                      width="150"
                      class="pl-3"
                    >
                      Severity:
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-chip
                        x-small
                        :color="severityColor(item.severity)"
                        class="mr-1 text-white"
                      >
                        {{ severityType(item.severity) }}
                      </v-chip>
                    </td>

                    <td
                      v-if="expandItem.value === 'message'"
                      width="150"
                      class="pl-3"
                    >
                      Message:
                    </td>
                    <td v-if="expandItem.value === 'message'">
                      {{ item.message ? item.message : "-" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import SearchBar from "@/components/SearchBar";

export default {
  components: {
    SearchBar,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      options: {},

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {},
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  methods: {
    severityType(status) {
      if (status === 1) {
        return "Low";
      } else if (status === 2) {
        return "Medium";
      } else if (status === 3) {
        return "High";
      } else if (status === 4) {
        return "Critical";
      } else {
        return "Information";
      }
    },
    severityColor(status) {
      if (status === 1) {
        return "neutral-3";
      } else if (status === 2) {
        return "good-3";
      } else if (status === 3) {
        return "medium-3";
      } else if (status === 4) {
        return "bad-3";
      } else {
        return "neutral-5";
      }
    },
    severityClass(status) {
      if (status === 1) {
        return "signal-icon--low";
      } else if (status === 2) {
        return "signal-icon--medium";
      } else if (status === 3) {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
